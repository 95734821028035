import format from 'date-fns/format';

let vAPIURL = "";
let vBoolGolf = false;
let vBoolTravel = false;
let vBoolDev = false;
let vBoolDev2 = false;
let vGlobalDateNow = format( new Date(), "dd-MMM-yyyy" );
let vRuleId = 0

switch( window.location.host.toLowerCase() ) {
	case "dashboard.sjgc.askara-tech.com":
		vAPIURL = "https://api.gm360.suvarnagolf.com";
		vBoolGolf = true;
		vBoolDev = false;
		break;
	case "dashboard2.sjgc.askara-tech.com":
		vAPIURL = "https://server5b.api.gm360.askara-tech.com";
		vBoolGolf = true;
		vBoolDev = false;
		break;
	case "dev.dashboard.sjgc.askara-tech.com":
		vAPIURL = "https://apidev.gm360.suvarnagolf.com";
		vBoolGolf = true;
		vBoolDev = true;
		break;
	case "demo1.askara-tech.com":
		vAPIURL = "https://api.demo1.gm360.suvarna-mi.com";
		vBoolGolf = true;
		vBoolDev = true;
		vGlobalDateNow = "04-Dec-2023";
		vRuleId = 1929
		break;
	case "demo2.askara-tech.com":
		vAPIURL = "https://apidev.gm360.suvarnagolf.com";
		vBoolGolf = true;
		// vBoolDev = true;
		vGlobalDateNow = "04-Dec-2023";
		vBoolDev2 = true;
		break;
	case "demo3.askara-tech.com":
		vAPIURL = "https://apidev.gm360.suvarnagolf.com";
		vBoolGolf = true;
		// vBoolDev = true;
		vGlobalDateNow = "04-Dec-2023";
		vBoolDev2 = true;
		break;
	case "xreport.onesoft.id":
		vAPIURL = "https://xapi.onesoft.id";
		vBoolTravel = true;
		break;
	case "dashboard.his.itm.suvarna-mi.com":
		vAPIURL = "https://api.his.itm.suvarna-mi.com";
		vBoolTravel = true;
		break;
	case "dashboard.demo.itm.suvarna-mi.com":
		vAPIURL = "https://api.demo.itm.suvarna-mi.com";
		vBoolTravel = true;
		break;
	default:
		vAPIURL = process.env.REACT_APP_API_URL;
		vBoolGolf = true;
		vBoolTravel = false;
		// vBoolDev = true;
		// vBoolDev2 = true;
		// vGlobalDateNow = "04-Dec-2023";
		break
}

export { vAPIURL, vBoolGolf, vBoolTravel, vBoolDev, vGlobalDateNow, vBoolDev2, vRuleId }